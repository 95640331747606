<template>
  <div>
    <LeadConfirmationFormStep :progress-value="progressValue" />
  </div>
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import LeadConfirmationFormStep from 'chimera/realEstateAgency/components/form/steps/leadConfirmation/LeadConfirmationFormStep'

export default {
  name: 'LeadCompletedPage',

  components: {
    LeadConfirmationFormStep,
  },

  extends: AbstractPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Uw aanvraag is goed afgerond',
      path: '/offertes-aanvragen/aanvraag-afgerond',
      progressValue: 100,
    }
  },
}
</script>
