<template>
  <div>
    <slot />
  </div>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import transitionsHelper from 'chimera/realEstateAgency/mixins/transitionsHelper'

export default {
  name: 'FormPage',

  extends: AbstractFormPage,

  mixins: [transitionsHelper],
}
</script>
