/**
 * Mixin to be used for using service.
 *
 * @returns {object}
 */
import {
  realEstateAppraisalConsumerNL,
  realEstateAppraisalConsumerBE,
  realEstatePurchaseConsumerNL,
  realEstateSalesConsumerNL,
  realEstateSalesConsumerBE,
} from 'chimera/realEstateAgency/service'

export default {
  computed: {
    /**
     * @returns {*}
     */
    service() {
      return this.$store.getters['lead/getData']('service')
    },
  },

  methods: {
    /**
     * @returns {boolean}
     */
    serviceIsPurchase() {
      return this.service === realEstatePurchaseConsumerNL.id
    },

    /**
     * @returns {boolean}
     */
    serviceIsAppraisal() {
      return (
        this.service === realEstateAppraisalConsumerNL.id ||
        this.service === realEstateAppraisalConsumerBE.id
      )
    },

    /**
     * @returns {boolean}
     */
    serviceIsSales() {
      return (
        this.service === realEstateSalesConsumerNL.id ||
        this.service === realEstateSalesConsumerBE.id
      )
    },
  },
}
