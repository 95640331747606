<template>
  <div>
    <LeadSubmitFormStep :progress-value="100" />
  </div>
</template>

<script>
import LeadSubmitFormStep from '~/components/form/steps/leadSubmit/LeadSubmitFormStep'
import FormPage from '~/components/form/FormPage'

export default {
  name: 'LeadSubmitPage',

  components: {
    LeadSubmitFormStep,
  },

  extends: FormPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Aanvraag aan het versturen',
      path: '/offertes-aanvragen/versturen',
      checkoutStep: 2,
    }
  },
}
</script>
